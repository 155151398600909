// styles.js

const style1 = {
  fontSize: "14px",
  color: "#344054",
  fontWeight: "500",
  lineHeight: "35px",
};

const style2 = {
  fontSize: "14px",
  color: "#344054",
  fontWeight: "500",
};

const style3 = {
  fontSize: "14px",
  color: "#344054",
  fontWeight: "500",
  marginLeft: "10px",
};

const stylelabel = {
  fontSize: "16px",
  color: "#344054",
  fontWeight: "500",
};

const formatKey = (key) => {
  switch (key) {
    case 'dashboard': return 'Dashboard';
    case 'branch': return 'Branch';
    case 'tables': return 'Tables';
    case 'orders': return 'Orders';
    case 'coupons': return 'Coupons';
    case 'menus': return 'Menus';
    case 'vouchers': return 'Vouchers';
    case 'stock': return 'Stock';
    case 'enquiries': return 'Enquiries';
    case "email_marketing": return "Email Marketing";
    case "subscriptions": return "Subscriptions";
    case 'tour_groups': return 'Tour Groups';
    case 'KPGPT': return 'KPGPT';
    default: return key.toLowerCase(); // Default to lowercase for all other keys
  }
};


const formatKey11 = (key) => {
  switch (key) {
    case 'Dashboard': return 'dashboard';
    case 'Branch': return 'branch';
    case 'Tables': return 'tables';
    case 'Orders': return 'orders';
    case 'Menus': return 'menus';
    case 'Coupons': return 'coupons';
    case 'Stock': return 'stock';
    case 'Vouchers': return 'vouchers';
    case 'Enquiries': return 'enquiries';
    case "Email Marketing": return "email_marketing";
    case "Subscriptions": return "subscriptions";
    case 'Tour Groups': return 'tour_groups';
    case 'KPGPT': return 'KPGPT';
    default: return key.toLowerCase(); // Default to lowercase for all other keys
  }
};

const formatKeySetting = (key) => {
  switch (key) {
    case 'Delivery':
      return 'delivery';
    case 'Pickup':
      return 'pickup';
    case 'Dine In':
      return 'dine_in';
    case 'Payment Provider':
      return 'payment_provider';
    case 'POS':
      return 'pos';
    case 'Vat Charges':
      return 'vat_charges';
    case 'Properties Management':
      return 'properties_management';
    case 'Group Setup':
      return 'group_setup';
    case 'Printer':
      return 'printer';
    case 'Tour Setup':
      return 'tour_setup';
    case 'CMS Settings':
      return 'cms_settings';
    case 'Language Settings':
      return 'language_settings';
    case 'Discount':
      return 'discount';
    case 'Tips':
      return 'tips';
    case 'Options':
      return 'options';
      case 'Inventory':
      return 'inventory';
    default:
      return key; // Default to lowercase for all other keys
  }
};

const formatKeyReport = (key) => {
  switch (key) {
    case 'Revenue Reports':
      return 'revenue_reports';
    case 'Order Reports':
      return 'order_reports';
    case 'Product Reports':
      return 'product_reports';
    case 'General Reports':
      return 'general_reports';
    case 'Billing Reports':
      return 'billing_reports';
    case 'Voucher Reports':
      return 'voucher_reports';
    case 'Tour Group Reports':
      return 'tour_group_reports';
    default:
      return key; // Default to lowercase for all other keys
  }
};


const customStyles = {
  menuList: (provided) => ({
    ...provided,
    maxHeight: '200px', // Set the maximum height of the dropdown menu
  }),
  control: (provided) => ({
    ...provided,
    backgroundColor: '#f5f8fa', // Apply your custom background color here
  }),
};


const formatAccess = (accessArray) => {
  return accessArray?.filter(item => item.isCheck)?.map(item => item?.label?.toLowerCase());
};

const reports = [
  "Revenue Reports",
  "Order Reports",
  "Product Reports",
  "General Reports",
  "Billing Reports",
  "Voucher Reports",
  "Tour Group Reports"
];

const settings = [
  "Delivery",
  "Pickup",
  "Dine In",
  "Payment Provider",
  "POS",
  "Vat Charges",
  "Properties Management",
  "Group Setup",
  "Printer",
  "Tour Setup",
  "CMS Settings",
  "Language Settings",
  "Discount",
  "Tips",
  "Options",
  // "Inventory"
];

const formatResponse = (permissions) => {
  return permissions?.map(item => ({
    key: formatKey11(item.key),
    access: formatAccess(item.access)
  }));
};


const formatResponseSetting = (Settings) => {
  return Settings?.length > 0 && Settings.map(item => ({
    key: formatKeySetting(item.setting),
    access: formatAccess(item.access)
  }))
};

const formatResponseReport = (Reports) => {
  return Reports?.length > 0 && Reports.map(item => ({
    key: formatKeyReport(item.report),
    access: formatAccess(item.access)
  }))
};

export {
  style1, style2, style3, stylelabel, formatKey, formatKey11, formatKeyReport, formatKeySetting
  , formatAccess, reports, customStyles, settings, formatResponse, formatResponseSetting, formatResponseReport
};
