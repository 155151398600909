import React, { useEffect, useState } from "react";
import GroupAdminHeader from "../../../../Comman/Header";
import api from "../../../../../CommonApi/axios";
import GroupAdminUperSide from "../../../../Comman/UperSide";
import GroupAdminSidebar from "../../../../Comman/Sidebar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NoteState from "../CommanBranch/NoteState";
import { connect } from "react-redux";
import headers from "../../../../../CommonApi/headers";
import useDrawer from "../../../../../context/DrawerContext";
import { applyFilterMeHandlers } from "../../../../Comman/Error/jqueryUtils";
import { showToastCommon } from "../../../../Comman/Toastify/Toastify";
import { showSuccessNotification } from "../../../../Comman/Swal/Swal";
import { UserPermissionAllData, userPermissionType } from "../../../../Comman/UserPermission";

function GroupAdminVatCharges(props) {
  const { branchID } = props;
  ///////////////////////// Branch List //////////////////////////
  useEffect(() => {
    applyFilterMeHandlers()
  }, []);
  //////////////////////// Get Vat ChargesList ////////////////

  useEffect(() => {
    groupAdminVatCharges();
  }, [branchID]);

  const [food_Delivery_vat, setfood_Delivery_vat] = useState("");
  const [food_Pickup_vat, setfood_Pickup_vat] = useState("");
  const [food_Dinein_vat, setfood_Dinein_vat] = useState("");
  const [Softdrinks_Delivery_vat, setSoftdrinks_Delivery_vat] = useState("");
  const [Softdrinks_Pickup_vat, setSoftdrinks_Pickup_vat] = useState("");
  const [Softdrinks_Dinein_vat, setSoftdrinks_Dinein_vat] = useState("");
  const [Alcoholic_Delivery_vat, setAlcoholic_Delivery_vat] = useState("");
  const [Alcoholic_Pickup_vat, setAlcoholic_Pickup_vat] = useState("");
  const [Alcoholic_Dinein_vat, setAlcoholic_Dinein_vat] = useState("");

  const [Food_ID, setFood_ID] = useState("");
  const [Soft_drinks_ID, setSoft_drinks_ID] = useState("");
  const [Alcoholic_ID, setAlcoholic_ID] = useState("");

  function groupAdminVatCharges() {
    setAlcoholic_Delivery_vat("");
    setAlcoholic_Dinein_vat("");
    setAlcoholic_ID("");
    setAlcoholic_Pickup_vat("");
    setFood_ID("");
    setSoft_drinks_ID("");
    setSoftdrinks_Delivery_vat("");
    setSoftdrinks_Dinein_vat("");
    setSoftdrinks_Pickup_vat("");
    setfood_Delivery_vat("");
    setfood_Dinein_vat("");
    setfood_Pickup_vat("");

    api
      .get(`/groupAdmin/settings/VAT_charges/${branchID}`, {
        headers,
      })
      .then((res) => {
        const tableData = res.data.data;
        var newList = tableData.filter((e) => e.VAT_category === "food");
        setFood_ID(newList.length > 0 ? newList[0]?._id : "");
        setfood_Delivery_vat(
          newList.length > 0 ? newList[0]?.VAT_delivery : ""
        );
        setfood_Pickup_vat(newList.length > 0 ? newList[0]?.VAT_pickup : "");
        setfood_Dinein_vat(newList.length > 0 ? newList[0]?.VAT_dineIn : "");

        var NewSoft_drinks = tableData.filter(
          (e) => e.VAT_category === "softdrinks"
        );
        setSoft_drinks_ID(
          NewSoft_drinks.length > 0 ? NewSoft_drinks[0]?._id : ""
        );
        setSoftdrinks_Delivery_vat(
          NewSoft_drinks.length > 0 ? NewSoft_drinks[0]?.VAT_delivery : ""
        );
        setSoftdrinks_Pickup_vat(
          NewSoft_drinks.length > 0 ? NewSoft_drinks[0]?.VAT_pickup : ""
        );
        setSoftdrinks_Dinein_vat(
          NewSoft_drinks.length > 0 ? NewSoft_drinks[0]?.VAT_dineIn : ""
        );

        var NewAlcoholic = tableData.filter(
          (e) => e.VAT_category === "alcoholicdrinks"
        );

        setAlcoholic_ID(NewAlcoholic.length > 0 ? NewAlcoholic[0]?._id : "");
        setAlcoholic_Delivery_vat(
          NewAlcoholic.length > 0 ? NewAlcoholic[0]?.VAT_delivery : ""
        );
        setAlcoholic_Dinein_vat(
          NewAlcoholic.length > 0 ? NewAlcoholic[0]?.VAT_dineIn : ""
        );
        setAlcoholic_Pickup_vat(
          NewAlcoholic.length > 0 ? NewAlcoholic[0]?.VAT_pickup : ""
        );
      })
      .catch((err) => {
        if (err.response) {
          setAlcoholic_Delivery_vat("");
          setAlcoholic_Dinein_vat("");
          setAlcoholic_ID("");
          setAlcoholic_Pickup_vat("");
          setFood_ID("");
          setSoft_drinks_ID("");
          setSoftdrinks_Delivery_vat("");
          setSoftdrinks_Dinein_vat("");
          setSoftdrinks_Pickup_vat("");
          setfood_Delivery_vat("");
          setfood_Dinein_vat("");
          setfood_Pickup_vat("");
        }
      });
  }

  //////////////////////// Get Vat ChargesList ////////////////

  function handalSubmit(event, categoryValue) {
    event.preventDefault();

    let item = {
      branch_id: branchID,
      VAT_delivery: "",
      VAT_pickup: "",
      VAT_dineIn: "",
      VAT_category: categoryValue,
    };

    switch (categoryValue) {
      case "food":
        item.VAT_delivery = Number(food_Delivery_vat);
        item.VAT_pickup = Number(food_Pickup_vat);
        item.VAT_dineIn = Number(food_Dinein_vat);
        break;
      case "softdrinks":
        item.VAT_delivery = Number(Softdrinks_Delivery_vat);
        item.VAT_pickup = Number(Softdrinks_Pickup_vat);
        item.VAT_dineIn = Number(Softdrinks_Dinein_vat);
        break;
      case "alcoholicdrinks":
        item.VAT_delivery = Number(Alcoholic_Delivery_vat);
        item.VAT_pickup = Number(Alcoholic_Pickup_vat);
        item.VAT_dineIn = Number(Alcoholic_Dinein_vat);
        break;
      default:
        break;
    }

    api
      .post(
        `/groupAdmin/settings/VAT_charges`,
        item,
        { headers }
      )
      .then((res) => {
        const tableData = res.data.message;
        showSuccessNotification("Vat charges have been added successfully.")
      })
      .catch((err) => {
        if (err.response) {
          showToastCommon(err?.response?.data?.error?.message)
        }
      });
  }

  /////////////////////////////// Payment Provider List by id ///////////////////

  function EdithandalSubmit(event, categoryValue) {
    event.preventDefault();

    // Define item based on the category value
    let item = {
      branch_id: branchID,
      VAT_delivery: "",
      VAT_pickup: "",
      VAT_dineIn: "",
      VAT_category: categoryValue,
    };

    let itemId = "";

    switch (categoryValue) {
      case "food":
        item.VAT_delivery = Number(food_Delivery_vat);
        item.VAT_pickup = Number(food_Pickup_vat);
        item.VAT_dineIn = Number(food_Dinein_vat);
        itemId = Food_ID;
        break;
      case "softdrinks":
        item.VAT_delivery = Number(Softdrinks_Delivery_vat);
        item.VAT_pickup = Number(Softdrinks_Pickup_vat);
        item.VAT_dineIn = Number(Softdrinks_Dinein_vat);
        itemId = Soft_drinks_ID;
        break;
      case "alcoholicdrinks":
        item.VAT_delivery = Number(Alcoholic_Delivery_vat);
        item.VAT_pickup = Number(Alcoholic_Pickup_vat);
        item.VAT_dineIn = Number(Alcoholic_Dinein_vat);
        itemId = Alcoholic_ID;
        break;
      default:
        break;
    }

    // Make API call to update VAT charges
    api
      .patch(`/groupAdmin/settings/VAT_charges/${itemId}`, item, { headers })
      .then((res) => {
        const tableData = res.data.message;
        showSuccessNotification("Vat charges have been edited successfully.")
      })
      .catch((err) => {
        if (err.response) {
          showToastCommon(err?.response?.data?.error?.message)
        }
      });
  }


  const { drawerPos, handleDrawer, maingetViewDetails } = useDrawer();
  const userPermissionType_ = userPermissionType === "groupadmin"


  const SAVE = <div className="modal-footer flex-center">
    {(userPermissionType_ ||
      UserPermissionAllData?.role_details?.settings?.find(permission =>
        permission?.key === "vat_charges" &&
        (permission?.access?.includes("create") || permission?.access?.includes("edit"))
      )) && (
        <button
          type="submit"
          className="btn btn-primary"
        >
          <span className="indicator-label">
            Save
          </span>
        </button>)}
  </div>

  const storedDataSub = localStorage.getItem('DataSub');
  const DataSub = storedDataSub ? JSON.parse(storedDataSub) : null;
  let shouldShowCouponVoucher;
  // const shouldShowCouponVoucher = DataSub && (DataSub.status === "active" || DataSub.status === "trial") && DataSub.features

  // console.log("shouldShowCouponVoucher" ,shouldShowCouponVoucher)


  return (
    <React.Fragment>
      <div
        id="kt_body"
        className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
      >
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            <GroupAdminSidebar drawerPos={drawerPos} handleDrawer={handleDrawer} />
            <div
              className="wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <GroupAdminHeader />

              <div
                className=" d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div id="kt_content_container" className="mx-7">
                  <main className={`main_ ${maingetViewDetails.join(" ")}`}>
                    <h1
                      className="anchor fw-bolder mb-5"
                      id="vertical-horizontal-scroll"
                    >
                      Settings
                    </h1>
                    <hr />
                    <div className="row col-md-12">
                      <div
                        className="col-md-9 d-inline justify-content-end"
                        data-kt-docs-table-toolbar="base"
                      >
                        <h3
                          className="anchor fw-bolder mb-5 d-inline"
                          id="custom-form-control"
                        >
                          Vat Charges
                        </h3>
                      </div>

                      <div
                        className="col-md-3 d-inline justify-content-end"
                        data-kt-docs-table-toolbar="base"
                      >
                        <NoteState />
                      </div>
                    </div>
                    <div className="py-5">
                      <div className="card card-docs mb-2">
                        <div className="card-body fs-6 py-10 px-10 py-lg-15 px-lg-15 text-gray-700">
                          <div className="rounded border p-10">
                            <div className="py-5">
                              <form
                                onSubmit={(e) => {
                                  Food_ID !== ""
                                    ? EdithandalSubmit(e, "food")
                                    : handalSubmit(e, "food");
                                }}
                              >
                                <div className="row col-md-12 mb-4">
                                  <div className="col-md-3">
                                    <h5>Categories</h5>
                                    <br />
                                    <strong>Food</strong>
                                  </div>
                                  <div className="col-md-3">
                                    <h5>Delivery</h5>
                                    <div className="my-5">
                                      {" "}
                                      <input
                                        type="text"
                                        className="filterme form-control form-control-solid"
                                        placeholder="Enter Vat Charges"
                                        required
                                        disabled={shouldShowCouponVoucher ? shouldShowCouponVoucher?.includes("delivery") ? false : true : false}
                                        value={food_Delivery_vat}
                                        onChange={(e) =>
                                          setfood_Delivery_vat(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <h5 className=""> Pick up</h5>
                                    <div className="my-5">
                                      {" "}
                                      <input
                                        type="text"
                                        className="filterme form-control form-control-solid"
                                        placeholder="Enter Vat Charges"
                                        required
                                        disabled={shouldShowCouponVoucher ? shouldShowCouponVoucher?.includes("pickup") ? false : true : false}
                                        value={food_Pickup_vat}
                                        onChange={(e) =>
                                          setfood_Pickup_vat(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <h5>Dine in</h5>
                                    <div className="my-5">
                                      {" "}
                                      <input
                                        type="text"
                                        className="filterme form-control form-control-solid"
                                        placeholder="Enter Vat Charges"
                                        required
                                        disabled={shouldShowCouponVoucher ? shouldShowCouponVoucher?.includes("dinein") ? false : true : false}
                                        value={food_Dinein_vat}
                                        onChange={(e) =>
                                          setfood_Dinein_vat(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>

                                {SAVE}
                              </form>
                              <br />

                              <form
                                onSubmit={(e) => {
                                  Soft_drinks_ID !== ""
                                    ? EdithandalSubmit(e, "softdrinks")
                                    : handalSubmit(e, "softdrinks");
                                }}
                              >
                                <div className="row col-md-12 mb-4">
                                  <div className="col-md-3">
                                    <h5>Categories</h5>
                                    <br />
                                    <strong>Soft drinks</strong>
                                  </div>
                                  <div className="col-md-3">
                                    <h5>Delivery</h5>
                                    <div className="my-5">
                                      {" "}
                                      <input
                                        type="text"
                                        className="filterme form-control form-control-solid"
                                        placeholder="Enter Vat Charges"
                                        required
                                        disabled={shouldShowCouponVoucher ? shouldShowCouponVoucher?.includes("delivery") ? false : true : false}
                                        value={Softdrinks_Delivery_vat}
                                        onChange={(e) =>
                                          setSoftdrinks_Delivery_vat(
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <h5> Pick up</h5>
                                    <div className="my-5">
                                      {" "}
                                      <input
                                        type="text"
                                        className="filterme form-control form-control-solid"
                                        placeholder="Enter Vat Charges"
                                        required
                                        disabled={shouldShowCouponVoucher ? shouldShowCouponVoucher?.includes("pickup") ? false : true : false}
                                        value={Softdrinks_Pickup_vat}
                                        onChange={(e) =>
                                          setSoftdrinks_Pickup_vat(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <h5>Dine in</h5>
                                    <div className="my-5">
                                      {" "}
                                      <input
                                        type="text"
                                        className="filterme form-control form-control-solid"
                                        placeholder="Enter Vat Charges"
                                        required
                                        disabled={shouldShowCouponVoucher ? shouldShowCouponVoucher?.includes("dinein") ? false : true : false}
                                        value={Softdrinks_Dinein_vat}
                                        onChange={(e) =>
                                          setSoftdrinks_Dinein_vat(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                {SAVE}
                              </form>
                              <br />
                              <form
                                onSubmit={(e) => {
                                  Alcoholic_ID !== ""
                                    ? EdithandalSubmit(e, "alcoholicdrinks")
                                    : handalSubmit(e, "alcoholicdrinks");
                                }}
                              >
                                <div className="row col-md-12 mb-4">
                                  <div className="col-md-3">
                                    <h5>Categories</h5>
                                    <br />
                                    <strong>Alcoholic Drinks</strong>
                                  </div>
                                  <div className="col-md-3">
                                    <h5>Delivery</h5>
                                    <div className="my-5">
                                      {" "}
                                      <input
                                        type="text"
                                        className="filterme form-control form-control-solid"
                                        placeholder="Enter Vat Charges"
                                        required
                                        disabled={shouldShowCouponVoucher ? shouldShowCouponVoucher?.includes("delivery") ? false : true : false}
                                        value={Alcoholic_Delivery_vat}
                                        onChange={(e) =>
                                          setAlcoholic_Delivery_vat(
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <h5> Pick up</h5>
                                    <div className="my-5">
                                      {" "}
                                      <input
                                        type="text"
                                        className="filterme form-control form-control-solid"
                                        placeholder="Enter Vat Charges"
                                        required
                                        disabled={shouldShowCouponVoucher ? shouldShowCouponVoucher?.includes("pickup") ? false : true : false}
                                        value={Alcoholic_Pickup_vat}
                                        onChange={(e) =>
                                          setAlcoholic_Pickup_vat(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <h5>Dine in</h5>
                                    <div className="my-5">
                                      {" "}
                                      <input
                                        type="text"
                                        className="filterme form-control form-control-solid"
                                        placeholder="Enter Vat Charges"
                                        required
                                        disabled={shouldShowCouponVoucher ? shouldShowCouponVoucher?.includes("dinein") ? false : true : false}
                                        value={Alcoholic_Dinein_vat}
                                        onChange={(e) =>
                                          setAlcoholic_Dinein_vat(e.target.value)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>

                                {SAVE}
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </main>
                </div>
              </div>
            </div>
          </div>
          <GroupAdminUperSide />
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  branchID: state.branch.branchID,
});

export default connect(mapStateToProps)(GroupAdminVatCharges);
